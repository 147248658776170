import { useAuthStore } from '@/stores/authStore';

export default defineNuxtRouteMiddleware((to, from) => {
  const { user, access_token } = useAuthStore();

  console.log(user);
  // const NON_PROTECTED_ROUTES = ['/login', '/registreer', '/'];
  // const PROTECTED_ROUTES = ['/login', '/registreer', '/'];

  // if (!NON_PROTECTED_ROUTES.includes(to.path) && !user) {
  //   return navigateTo('/login');
  // }

  return true;
});
